import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@coreui/coreui/dist/css/coreui.min.css'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './components/common/Footer';
import Navbar from './components/common/Navbar';
import LocationPage from './components/location-page/LocationPage';
import CustomerLandingPage from './components/landing-pages/customer/CustomerLandingPage';
import ScrapperLandingPage from './components/landing-pages/scrapper/ScrapperLandingPage';
import HaulerLandingPage from './components/landing-pages/hauler/HaulerLandingPage';
import ScrapperSignUp from './components/landing-pages/scrapper/sign-up/ScrapperSignUp';

ReactDOM.render(
  <BrowserRouter>
    <Navbar />
    <Routes>
      <Route path="/" element={<CustomerLandingPage />} />
      <Route path="/scrappers" element={<ScrapperLandingPage />} />
      <Route path="/scrappers/signup" element={<ScrapperSignUp />} />
      <Route path="/haulers" element={<HaulerLandingPage />} />
      <Route path="/locations/denver-CO" element={<LocationPage city="Denver" state="CO" />} />
      <Route path="/locations/dallas-TX" element={<LocationPage city="Dallas" state="TX" />} />

      {/* <Route path="/locations" element={<Blog />}>
        <Route path="" element={<Posts />} />
        <Route path=":postSlug" element={<Post />} />
      </Route> */}
    </Routes>
    <Footer />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
