import { CCol, CFormInput, CRow } from "@coreui/react";
import React, { useState } from "react";
import { formatPhoneNumber } from "utils/PhoneFormatter";
import { ScrapperInfo } from "../ScrapperSignUp";
import StepWrapper from "./StepWrapper";
// import Autocomplete from "react-google-autocomplete"

interface AboutMeProps {
  info: ScrapperInfo
  onChange: (value: React.SetStateAction<ScrapperInfo>) => void
}

export default function AboutMe({ info, onChange }: AboutMeProps) {
  // const [firstName, setFirstName] = useState<string>()
  // const [middleName, setMiddleName] = useState<string>()
  // const [lastName, setLastName] = useState<string>()
  const [phone, setPhone] = useState<string>()
  // const [address, setAddress] = useState<string>()

  const handlePhoneInput = (e: any) => {
    // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setInputValue
    setPhone(formattedPhoneNumber);
  };


  return (
    <StepWrapper title="Tell us about yourself.">
      <CRow>
        <CCol>
          <small className="ms-1">First Name*</small>
          <CFormInput
            value={info.firstName}
            placeholder="Frank"
            required
            className="mb-2"
            onChange={(e) => {
              info.firstName = e.currentTarget.value
              onChange(info)
            }}
          />
          <small className="ms-1">Middle Name</small>
          <CFormInput
            value={info.middleName}
            placeholder="Hank"
            className="mb-2"
            onChange={(e) => {
              info.middleName = e.currentTarget.value
              onChange(info)
            }}
          />
          <small className="ms-1">Last Name*</small>
          <CFormInput
            value={info.lastName}
            placeholder="McTesterson"
            className="mb-2"
            onChange={(e) => {
              info.lastName = e.currentTarget.value
              onChange(info)
            }}
          />
          <small className="ms-1">Phone*</small>
          <CFormInput
            value={phone}
            placeholder="(123) 456-7890"
            className="mb-2"
            onChange={(e) => {
              handlePhoneInput(e)
              info.phone = e.currentTarget.value
              onChange(info)
            }}
          />
          <small className="ms-1">Address*</small>
          <CFormInput
            value={info.address}
            placeholder="123 Main St"
            onChange={(e) => {
              info.address = e.currentTarget.value
              onChange(info)
            }}
          />
          {/* <Autocomplete
            className="form-control"
            apiKey="AIzaSyB58tsR63HZPO0BJdXiu1faTMdwxfLMQsM"
            onPlaceSelected={(place) => {
              info.address = "Test"
              onChange(info)
            }}
            options={{ types: "address" }}
          /> */}
        </CCol>
      </CRow>
    </StepWrapper>

  );
}