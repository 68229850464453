import React, { useState } from 'react';
import './Hero.css';
import { CButton, CCol, CContainer, CImage, CInputGroup, CNav, CNavItem, CNavLink, CRow, CTabContent } from '@coreui/react'
import "font-awesome/css/font-awesome.min.css"
import "../../../../assets/font-awesome/css/all.css"
import Autocomplete from "react-google-autocomplete";
import heroImage from "../../../../assets/img/svg/hero-img-2.svg"

interface HeroProps {
    city: string
    state: string
}

function Hero({ city, state }: HeroProps) {
    const [activeKey, setActiveKey] = useState(1)

    return (
        <CContainer fluid className="App-header">
            {/* <div className="App-background"></div> */}
            <CRow className="mt-5 mx-5">
                <CCol className="my-auto" sm={12} lg={8}>
                    <h1 className="mb-0 display-4 text-white">{city} waste services at the click of a button</h1>
                    <p className="lead text-white">The easy way to get waste and recycling services from local pros.</p>
                    <CNav variant="tabs" role="tablist" className="border-bottom-0 mt-5 text-secondary">
                        <CNavItem>
                            <CNavLink
                                active={activeKey === 1}
                                onClick={() => setActiveKey(1)}
                                className="text-reset text-decoration-none"
                            >
                                <p className="lead mb-0">Haul Away</p>
                            </CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink
                                active={activeKey === 2}
                                onClick={() => setActiveKey(2)}
                                className="text-reset text-decoration-none"
                            >
                                <p className="lead mb-0">Drop Off</p>
                            </CNavLink>
                        </CNavItem>
                    </CNav>
                    <CTabContent>
                        <CContainer className="hero-form p-3 mx-0 text-secondary">
                            <CRow>
                                <CCol>
                                    {activeKey === 1 && <p className="lead mb-0">Find waste services to your door.</p>}
                                    {activeKey === 2 && <p className="lead mb-0">Find locations to drop off your waste.</p>}
                                </CCol>
                            </CRow>
                            <CRow className="mt-2">
                                <CCol>

                                    <CInputGroup size="lg" className="mb-3">
                                        <Autocomplete
                                            className="form-control"
                                            apiKey="AIzaSyB58tsR63HZPO0BJdXiu1faTMdwxfLMQsM"
                                            onPlaceSelected={(place) => console.log(place)}
                                            options={{ types: "address" }}
                                        />
                                        <CButton type="button" color="success" href="https://thetrashgurus.com/en/">Search</CButton>
                                    </CInputGroup>
                                </CCol>
                            </CRow>
                        </CContainer>
                    </CTabContent>
                </CCol>
                <CCol sm={12} lg={12} className="my-5">
                    <CImage fluid src={heroImage} className="mx-auto d-block" />
                </CCol>
            </CRow>
        </CContainer >
    )
}

export default Hero
