import React from 'react';
import { CCol, CContainer, CRow } from '@coreui/react'
import "../../../../index.css"
import "font-awesome/css/font-awesome.min.css"
import "../../../../assets/font-awesome/css/all.css"
import ServiceBox from './ServiceBox'
import './Services.css'

function Services() {
    return (
        <CContainer fluid className="my-5 py-5 diagonal-box-clockwise">
            <div className="content-clockwise">
                <CRow>
                    <h1 className="display-5 mb-4">Services on Our Marketplace</h1>
                </CRow>
                <CRow>
                    <CCol lg="4" sm="6" xs="12" className="mb-1 px-4">
                        <ServiceBox
                            icon="trash-can"
                            title="Curbside Garbage"
                            description="Recurring trash pickups at the end of your driveway."
                        />

                    </CCol>
                    <CCol lg="4" sm="6" xs="12" className="mb-1 px-4">
                        <ServiceBox
                            icon="couch"
                            title="Junk Removal"
                            description="Get that old couch out of the basement... with one tap."
                        />
                    </CCol>
                    <CCol lg="4" sm="6" xs="12" className="mb-1 px-4">
                        <ServiceBox
                            icon="dumpster"
                            title="Roll Off Dumpsters"
                            description="For all the residential and commerical projects you can dream of."
                        />
                    </CCol>
                    <CCol lg="4" sm="6" xs="12" className="mb-1 px-4">
                        <ServiceBox
                            icon="recycle"
                            title="Shredding & E-Recycling"
                            description="Secure your data and do your part to help the environment."
                        />
                    </CCol>
                    <CCol lg="4" sm="6" xs="12" className="mb-1 px-4">
                        <ServiceBox
                            icon="toilet"
                            title="Portable Toilets"
                            description="Do we really need to explain this one? Delivered."
                        />
                    </CCol>
                    <CCol lg="4" sm="6" xs="12" className="mb-1 px-4">
                        <ServiceBox
                            icon="face-smile"
                            title="And more..."
                            description="Yeah, like a lot more!"
                        />
                    </CCol>
                </CRow>
            </div >
        </CContainer >
    )
}

export default Services
