import { CButton } from "@coreui/react";
import { info } from "console";
import React, { useState } from "react";
import { ScrapperInfo } from "../ScrapperSignUp";
import StepFormWrapper from "./StepWrapper";

export enum ScrapperType { individual, employee }

interface DriversLicenseProps {
  info: ScrapperInfo
  onChange: (value: React.SetStateAction<ScrapperInfo>) => void
}

export default function DriversLicense({ info, onChange }: DriversLicenseProps) {
  return (
    <>
      <StepFormWrapper title="Let's get you paid">
        <>
          <iframe title="stripeConnectOnboarding" src="https://www.google.com" />
          <CButton onClick={() => {
            info.hasDriversLicense = true
            onChange(info)
          }}>
            Verify Drivers License
          </CButton>
        </>
      </StepFormWrapper>
    </>
  );
}