import React, { useEffect } from "react";
import AppFeatures from "../sections/app-features/AppFeatures";
import Services from "../sections/services/Services";
import Hero from "../sections/hero/Hero";
import OurMission from "../sections/our-mission/OurMission";

export default function HaulerLandingPage() {
    useEffect(() => {
        document.title = "Trash Gurus | Haulers";
    }, []);

    return (
        <>
            <Hero>
                <>
                    <h1 className="mb-0 display-3 text-white">Trash.</h1>
                    <h1 className="display-3 text-white">On Demand.</h1>
                </>
            </Hero>
            <AppFeatures />
            <Services />
            <OurMission />
        </>
    );
}