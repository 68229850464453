import React, { useEffect, useState } from "react";
import AppFeatures from "../sections/app-features/AppFeatures";
import Services from "../sections/services/Services";
import Hero from "../sections/hero/Hero";
import OurMission from "../sections/our-mission/OurMission";
import Autocomplete from "react-google-autocomplete";
import { CNav, CNavItem, CNavLink, CTabContent, CContainer, CRow, CCol, CInputGroup, CButton } from "@coreui/react";

export default function CustomerLandingPage() {
	const [activeKey, setActiveKey] = useState(1)

	useEffect(() => {
		document.title = "Trash Gurus | Waste Simplified";
	}, []);

	return (
		<>
			<Hero>
				<>
					<h1 className="mb-0 display-1 text-white">Trash.</h1>
					<h1 className="display-1 text-white">On Demand.</h1>
					<p className="lead text-white">Affordable Pricing &#8226; Fast Online Ordering &#8226; Satisfaction Guaranteed</p>
					<CNav variant="tabs" role="tablist" className="border-bottom-0 mt-5 text-secondary">
						<CNavItem>
							<CNavLink
								active={activeKey === 1}
								onClick={() => setActiveKey(1)}
								className="text-reset text-decoration-none"
							>
								<p className="lead mb-0">Haul Away</p>
							</CNavLink>
						</CNavItem>
						<CNavItem>
							<CNavLink
								active={activeKey === 2}
								onClick={() => setActiveKey(2)}
								className="text-reset text-decoration-none"
							>
								<p className="lead mb-0">Drop Off</p>
							</CNavLink>
						</CNavItem>
					</CNav>
					<CTabContent className="mb-md-5 mb-sm-5 mb-5">
						<CContainer className="hero-form p-3 mx-0 text-secondary">
							<CRow>
								<CCol>
									{activeKey === 1 && <p className="lead mb-0">Find waste services to your door.</p>}
									{activeKey === 2 && <p className="lead mb-0">Find locations to drop off your waste.</p>}
								</CCol>
							</CRow>
							<CRow className="mt-2">
								<CCol>

									<CInputGroup size="lg" className="mb-3">
										<Autocomplete
											className="form-control"
											apiKey="AIzaSyB58tsR63HZPO0BJdXiu1faTMdwxfLMQsM"
											onPlaceSelected={(place) => console.log(place)}
											options={{ types: "address" }}
										/>
										<CButton type="button" className="tg-btn-success" href="https://thetrashgurus.com/en/">Search</CButton>
									</CInputGroup>
								</CCol>
							</CRow>
						</CContainer>
					</CTabContent>
				</>
			</Hero>
			<AppFeatures />
			<Services />
			<OurMission />
		</>
	);
}