import React, { useEffect, useState } from "react";
import Services from "../sections/services/Services";
import Hero from "../sections/hero/Hero";
import OurMission from "../sections/our-mission/OurMission";
import { CRow, CCol, CFormInput, CButton, CFormCheck } from "@coreui/react";
import { formatPhoneNumber } from "../../../utils/PhoneFormatter";
import ServiceBox from "../../location-page/sections/services/ServiceBox";
// import { useNavigate } from "react-router-dom";

export default function ScrapperLandingPage() {
	const [inputValue, setInputValue] = useState("")
	const [acceptedTerms, setAcceptedTerms] = useState(false)
	const [formSubmitted, setFormSubmitted] = useState(false)

	// const navigate = useNavigate();

	useEffect(() => {
		document.title = "Trash Gurus | Earn Money Scrapping";
	}, []);

	const handleInput = (e: any) => {
		// this is where we'll call our future formatPhoneNumber function that we haven't written yet.
		const formattedPhoneNumber = formatPhoneNumber(e.target.value);
		// we'll set the input value using our setInputValue
		setInputValue(formattedPhoneNumber);
	};

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setAcceptedTerms(event.target.checked);
	};

	const handleSubmit = () => {
		var phoneCleaned = inputValue
			.replace("(", "")
			.replace(")", "")
			.replace("-", "")
			.replace(" ", "")

		if (phoneCleaned.length === 10) {
			let formData = new FormData();
			formData.append(
				'PhoneNumber_countrycode',
				"1" + phoneCleaned
			);

			const requestOptions = {
				method: 'POST',
				mode: "no-cors",
				headers: { 'Content-Type': 'application/json' },
				body: formData
			} as RequestInit;
			fetch('https://forms.zohopublic.com/thetrashgurus/form/ScrapperPhoneNumberForm/formperma/L7ol6ipHJF3RX1FU8oRkgW1jeLdOdIwCtvMPj7IwAzU/htmlRecords/submit',
				requestOptions
			)
				.then(() => setFormSubmitted(true))
			// .then(data => navigate("/scrappers/signup"));
		}
	}

	return (
		<>
			<Hero>
				<>
					<CRow>
						<CCol className="mt-md-5 mt-sm-5 mt-5">
							<div className="text-white">
								<h1 className="mb-4 display-3" style={{ fontWeight: 900 }}>It pays to pick up trash right now</h1>
								{!formSubmitted &&
									<>
										<p className="mb-0 fw-bold" style={{ color: "#02512C" }}>Sign up to be a scrapper</p>
										<p style={{ fontSize: 16 }}>Let's start with a phone number - we'll confirm it's you</p>
									</>
								}
								{formSubmitted &&
									<>
										<p className="mb-0 fw-bold" style={{ color: "#02512C" }}>You're almost there!</p>
										<p style={{ fontSize: 16 }}>We've sent a link to your phone number. Download our app to become a scrapper!</p>
									</>
								}
							</div>
						</CCol>
					</CRow>
					{!formSubmitted && <CRow>
						<CCol lg="7" sm="7">
							<CRow>
								<CCol>
									<CFormInput
										placeholder="Mobile Phone Number"
										onChange={(e) => handleInput(e)}
										value={inputValue}
									/>
								</CCol>
							</CRow>
							<CRow className="mt-4 mb-md-5 mb-sm-5 mb-5 ">
								<CCol lg="8" sm="12">
									<CFormCheck
										onChange={handleCheckboxChange}
										label={
											<p
												className="text-white"
												style={{ fontSize: 12 }}
											>
												I agree to Trash Guru's{" "}
												<a
													href="https://app.termly.io/document/terms-of-use-for-online-marketplace/71366688-0729-4913-b32f-61ad7540e5d2"
													style={{ color: "#02512C" }}
												>
													Terms of Service
												</a>
											</p>
										}
									/>
								</CCol>
								<CCol lg="4" sm="4" xs="4" className="my-auto">
									<CButton
										className="text-white float-lg-end"
										shape="py-1 px-4 rounded-pill"
										color="tg-dark"
										disabled={!acceptedTerms}
										onClick={handleSubmit}
									>
										Next
									</CButton>
								</CCol>
								<CCol lg="12" sm="8" xs="8" className="my-auto">
									<p
										className="mb-0 float-end"
										style={{ fontSize: 14 }}
									>
										Already a Scrapper?{" "}
										<a
											href="https://thetrashgurus.com/en/"
											style={{ color: "#02512C" }}
										>
											Log In
										</a>
									</p>
								</CCol>
							</CRow>
						</CCol>
					</CRow>}
				</>
			</Hero>
			<div className="mx-5">
				<CRow className="my-5">
					<CCol className="mb-1">
						<h1 style={{ fontWeight: 900 }}>What is Trash Gurus?</h1>
						<p className="mt-2">Trash Gurus is a marketplace that connects scrappers (you!) with customers that need waste hauling and removal services.</p>
						<p className="mt-2">Get paid to complete delivery tasks for merchants or disposal tasks for waste haulers. Enjoy a flexible schedule and create a better world. Earn more money during peak hours with our built-in surge pricing.</p>

					</CCol>
				</CRow>
				<CRow className="my-5">
					<CCol className="mt-5">
						<h1 style={{ fontWeight: 900 }}>Why Trash Gurus?</h1>
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="6" md="8" sm="8" xs="8" className="mx-lg-auto mb-5 pe-lg-5">
						<ServiceBox
							icon="money"
							title="Make Extra Money"
							description="Work in your spare time to make extra money for all things you want to do!"
						/>
					</CCol>
					<CCol lg="6" md="8" sm="8" xs="8" className="mx-lg-auto mb-5 pe-lg-5">
						<ServiceBox
							icon="satellite-dish"
							title="Make More During Peak Times"
							description="See high-demand areas on the map and head that way to make more per job."
						/>
					</CCol>
					<CCol lg="6" md="8" sm="8" xs="8" className="mx-lg-auto mb-5 pe-lg-5">
						<ServiceBox
							icon="headset"
							title="24/7 Support"
							description="Never fear! We're here to help at all hours of the day for anything that issues that may arise."
						/>
					</CCol>
					<CCol lg="6" md="8" sm="8" xs="8" className="mx-lg-auto mb-5 pe-lg-5">
						<ServiceBox
							icon="recycle"
							title="Do Your Part"
							description="We're making it easy to make sustainable choices. Be a part of helping keep our planet clean."
						/>
					</CCol>
				</CRow>
			</div>
			<Services />
			<OurMission />
		</>
	);
}