import { CFormCheck } from "@coreui/react";
import React, { useState } from "react";
import { useEffect } from "react";
import { ScrapperInfo } from "../ScrapperSignUp";
import StepWrapper from "./StepWrapper";

export enum ScrapperType {
  individual = "Individual",
  employee = "Employee"
}

interface AccountTypeProps {
  info: ScrapperInfo
  onChange: (value: React.SetStateAction<ScrapperInfo>) => void
}

export default function AccountType({ info, onChange }: AccountTypeProps) {
  const [type, setType] = useState<ScrapperType>()

  useEffect(() =>
    setType(info.scrapperType),
    [info.scrapperType, setType]
  )

  return (
    <StepWrapper title="Which statement fits you best?">
      <>
        <CFormCheck
          type="radio"
          label="Individual looking for work"
          id="exampleRadios1"
          name="exampleRadios1"
          checked={type === ScrapperType.individual}
          onChange={() => {
            var test = info
            console.log("indivi")
            setType(ScrapperType.individual)
            test.scrapperType = ScrapperType.individual
            onChange(test)
          }}
        />
        <CFormCheck
          type="radio"
          label="Employee creating an account"
          id="exampleRadios2"
          name="exampleRadios2"
          checked={type === ScrapperType.employee}
          onChange={() => {
            var test = info
            console.log("employee")
            setType(ScrapperType.employee)
            test.scrapperType = ScrapperType.employee
            onChange(test)
          }}
        />
      </>
    </StepWrapper>
  );
}