import React from 'react';
import { CButton, CCol, CContainer, CImage, CRow } from '@coreui/react'
import "font-awesome/css/font-awesome.min.css"
import "../../../../assets/font-awesome/css/all.css"
import eco from '../../../../assets/img/eco-photo.png'
import './OurMission.css'

function OurMission() {
    return (
        <CContainer fluid className="bg-light my-5 diagonal-box-counterclockwise">
            <CRow className="content-counterclockwise my-5">
                <CCol className="my-auto py-5">
                    <CImage fluid src={eco} className="p-5" />
                </CCol>
                <CCol className="my-auto py-5">
                    <i className="fa fa-leaf rounded-3 p-3 mb-3 tg-bg-success text-white" />
                    <p className="tg-text-success">OUR WASTE REDUCTION MISSION</p>
                    <h2 className="display-6">Imagine a world without waste.</h2>
                    <p>Waste happens. What you do with the waste matters. Trash Gurus
                        is committed to the reduction of waste through recycling and
                        proper disposal of materials.
                    </p>
                    <CButton color="success" size="sm">JOIN THE MOVEMENT</CButton>
                </CCol>
            </CRow>
        </CContainer>
    )
}

export default OurMission


