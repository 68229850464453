import React, { useEffect } from "react";
import AppFeatures from "./sections/app-features/AppFeatures";
import Services from "./sections/services/Services";
import Hero from "./sections/hero/Hero";
import OurMission from "./sections/our-mission/OurMission";

interface LocationPageProps {
    city: string
    state: string
}

export default function LocationPage({
    city,
    state,
}: LocationPageProps) {
    useEffect(() => {
        document.title = `${city}, ${state} Waste Services | Trash Gurus`;
    }, [city, state])

    return (
        <>
            <Hero city={city} state={state} />
            <AppFeatures />
            <Services />
            <OurMission />
        </>
    );
}