import React from 'react';
import "../../../../index.css"
import './Hero.css';
import { CCol, CContainer, CImage, CRow } from '@coreui/react'
import "font-awesome/css/font-awesome.min.css"
import "../../../../assets/font-awesome/css/all.css"

import heroImage from "../../../../assets/img/svg/hero-img-2.svg"

interface HeroProps {
    children: React.ReactChild
}

function Hero({ children }: HeroProps) {
    return (
        <CContainer fluid className="App-header">
            <CRow className="w-100 mt-5">
                <CCol className="my-auto" sm={12} lg={7}>
                    {children}
                </CCol>
                <CCol sm={12} lg={5} className="mx-auto">
                    <CImage fluid src={heroImage} className="mx-auto d-block" />
                </CCol>
            </CRow>
        </CContainer >
    )
}

export default Hero
