import React from 'react';
import { CCol, CContainer, CImage, CRow } from '@coreui/react'
import "font-awesome/css/font-awesome.min.css"
import "../../assets/font-awesome/css/all.css"
import logo from '../../assets/img/logo/logo-smile.png'
import { Link } from 'react-router-dom';

function AppFeatures() {
  return (
    <CContainer fluid className="bg-dark pt-5 text-white">
      <CRow>
        <CCol sm={6} lg={3} className="mx-sm-auto mx-auto">
          <CImage fluid src={logo} />
        </CCol>
        <CCol sm={12} lg={3}>
          <ul className="list-unstyled">
            <li><h5><strong>Here to Help</strong></h5></li>
            <li>
              <a className="text-white text-decoration-none" href="https://help.thetrashgurus.com/portal/en/kb/customer-support">
                Customer Support
              </a>
            </li>
            <li>
              <a className="text-white text-decoration-none" href="https://help.thetrashgurus.com/portal/en/kb/scrapper-support">
                Scrapper Support
              </a>
            </li>
            <li>
              <a className="text-white text-decoration-none" href="https://help.thetrashgurus.com/portal/en/kb/merchants-support">
                Seller Support
              </a>
            </li>
          </ul>
        </CCol>
        <CCol sm={12} lg={3}>
          <ul className="list-unstyled">
            <li><h5><strong>Partner with Us</strong></h5></li>
            <li>
              <a className="text-white text-decoration-none" href="https://sell.thetrashgurus.com/home/sell-with-us/">
                Sell With Us</a>
            </li>
            <li>
              <Link to="scrappers" className="text-white text-decoration-none">
                Become A Scrapper
              </Link>
            </li>
            <li>
              <a className="text-white text-decoration-none" href="https://www.careers.thetrashgurus.com/">
                Careers
              </a>
            </li>
          </ul>
        </CCol>
        <CCol sm={12} lg={3}>
          <ul className="list-unstyled">
            <li><h5><strong>Legal</strong></h5></li>
            <li>
              <a className="text-white text-decoration-none" href="https://app.termly.io/document/privacy-policy/385d2d93-f324-4b37-bde9-0890556f67aa">
                Privacy Policy
              </a>
            </li>
            <li>
              <a className="text-white text-decoration-none" href="https://app.termly.io/document/disclaimer/49c065ae-bb4f-4df8-93f8-b07aecd64be5">
                Disclaimer
              </a>
            </li>
            <li>
              <a className="text-white text-decoration-none" href="https://app.termly.io/document/terms-of-use-for-online-marketplace/71366688-0729-4913-b32f-61ad7540e5d2">
                Terms of Use
              </a>
            </li>
          </ul>
        </CCol>
      </CRow>
      <hr />
      <CRow>
        <CCol sm={12} lg={4}>
          <p className="lead">
            DOWNLOAD OUR APP
            <div>
              <a className="text-white text-decoration-none" href="https://apps.apple.com/in/app/the-trash-gurus/id1590512559">
                <i className="fa fa-brands fa-app-store-ios me-3" />
              </a>
              <a className="text-white text-decoration-none" href="https://play.google.com/store/apps/details?id=com.thetrashgurus.customer">
                <i className="fa fa-brands fa-google-play" />
              </a>
            </div>
          </p>
        </CCol>
        <CCol sm={12} lg={4}>
          <p className="lead text-center">
            LOCATIONS
            <div>
              <Link to="locations/denver-CO/" className="text-white text-decoration-none">
                Denver
              </Link>
              <i className="fas fa-xmark mx-3" />
              <Link to="locations/dallas-TX/" className="text-white text-decoration-none">
                Dallas
              </Link>
            </div>
          </p>
        </CCol>
        <CCol sm={12} lg={4}>
          <p className="float-md-end lead float-center">
            FOLLOW US
            <div>
              <a className="text-white text-decoration-none" href="https://twitter.com/trashgurus">
                <i className="fa fa-brands fa-twitter me-3" />
              </a>
              <a className="text-white text-decoration-none" href="https://www.facebook.com/trashgurus/">
                <i className="fa fa-brands fa-facebook-square me-3" />
              </a>
              <a className="text-white text-decoration-none" href="https://www.instagram.com/trashgurus/">
                <i className="fa fa-brands fa-instagram me-3" />
              </a>
              <a className="text-white text-decoration-none" href="https://www.youtube.com/channel/UCmjP7zZJt8hXjdF8Q0tXs7g/featured">
                <i className="fa fa-brands fa-youtube me-3" />
              </a>
            </div>
          </p>
        </CCol>
      </CRow >
    </CContainer >
  )
}

export default AppFeatures
