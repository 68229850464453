import { CCol, CRow } from "@coreui/react";
import React from "react";

interface SignUpStepProps {
  title: string
  subtitle?: string
  icon?: string
  onOpenForm?: () => void
  currentStep?: boolean
  // showForm: boolean
  faded?: boolean
  // children?: JSX.Element
}

export default function SignUpStep({
  title,
  subtitle,
  icon,
  onOpenForm,
  currentStep,
  // showForm,
  faded,
  // children
}: SignUpStepProps) {
  return (
    <>
      <div className={faded ? "text-secondary" : ""} onClick={(e) => {
        e.preventDefault()
        onOpenForm && onOpenForm()
      }}>
        <CRow>
          <CCol lg={2} sm={2}>
            <i className={`fa fa-${icon || "ban"} me-3 ${icon ? "text-success" : "text-white"}`} />
          </CCol>
          <CCol lg={8} sm={8}>
            <h3 className="mb-0">
              {title}
            </h3>
            {subtitle &&
              <p className="mb-0">{subtitle}</p>
            }
          </CCol>
          {currentStep &&
            <CCol lg={2} sm={2} className="my-auto">
              <i className="fa fa-chevron-right" />
            </CCol>
          }
        </CRow>
      </div>

    </>


  );
}