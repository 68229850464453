import { CButton } from "@coreui/react";
import React from "react";
import { ScrapperInfo } from "../ScrapperSignUp";
import StepFormWrapper from "./StepWrapper";

export enum ScrapperType { individual, employee }

interface VideosProps {
  info: ScrapperInfo
  onChange: (value: React.SetStateAction<ScrapperInfo>) => void
}

export default function Videos({ info, onChange }: VideosProps) {
  return (
    <>
      <StepFormWrapper title="A few videos...">
        <CButton onClick={() => {
          info.watchedVideos = true
          onChange(info)
        }}>
          "Watch Videos"
        </CButton>
      </StepFormWrapper>
    </>
  );
}