import React from 'react';
import { CContainer, CRow } from '@coreui/react'
import "font-awesome/css/font-awesome.min.css"
import "../../../../assets/font-awesome/css/all.css"
import ServiceBox from './ServiceBox'
import './Services.css'

function Services() {
    return (
        <CContainer fluid className="my-5 py-5 diagonal-box-clockwise">
            <div className="content-clockwise">
                <CRow>
                    <h1 className="display-5 mb-4">Services on Our Marketplace</h1>
                </CRow>
                <CRow>
                    <ServiceBox
                        icon="trash-can"
                        title="Curbside Garbage"
                        description="Recurring trash pickups at the end of your driveway."
                    />
                    <ServiceBox
                        icon="couch"
                        title="Junk Removal"
                        description="Get that old couch out of the basement... with one tap."
                    />
                    <ServiceBox
                        icon="dumpster"
                        title="Roll Off Dumpsters"
                        description="For all the residential and commerical projects you can dream of."
                    />
                    <ServiceBox
                        icon="recycle"
                        title="Shredding & E-Recycling"
                        description="Secure your data and do your part to help the environment."
                    />
                    <ServiceBox
                        icon="toilet"
                        title="Portable Toilets"
                        description="Do we really need to explain this one? Delivered."
                    />
                    <ServiceBox
                        icon="face-smile"
                        title="And more..."
                        description="Yeah, like a lot more!"
                    />
                </CRow>
            </div>
        </CContainer >
    )
}

export default Services
