import React from 'react';
import "font-awesome/css/font-awesome.min.css"
import "../../../../assets/font-awesome/css/all.css"

interface ServiceBoxProps {
    icon: string
    title: string
    description: string
}

function ServiceBox({ icon, title, description }: ServiceBoxProps) {
    return (
        <>
            <i
                className={`fa fa-${icon} rounded-circle p-4 bg-tg-light text-tg-dark`}
                style={{ fontSize: 50 }}
            />
            <h3 className="mt-2" style={{ fontWeight: 900 }}>{title}</h3>
            <p>{description}</p>
        </>
    )
}

export default ServiceBox
