
interface ScrapperSignUpWrapperProps {
  children: JSX.Element[]
}

export default function ScrapperSignUpWrapper({ children }: ScrapperSignUpWrapperProps) {
  return (
    <>
      {children.map((child, index) => {
        return (
          <>
            {child}
            {index < children.length - 1 && <hr />}
          </>
        )
      })}
    </>

  );
}