import { CCard, CCardBody, CCardHeader, CCol, CContainer, CRow, CButton } from "@coreui/react";
import { useEffect, useState } from "react";
import SignUpStep from "./steps/SignUpStep";
// import Stripe, { loadStripe } from '@stripe/stripe-js';
import AccountType, { ScrapperType } from "./steps/AccountType";
import Vehicle, { VehicleType } from "./steps/Vehicle";
import ScrapperSignUpWrapper from "./ScrapperSignUpWrapper";
import Tax from "./steps/Tax";
import DirectDeposit from "./steps/DirectDeposit";
import Videos from "./steps/Videos";
import BackgroundCheck from "./steps/BackgroundCheck";
import AboutMe from "./steps/AboutMe";
import ReactCardFlip from 'react-card-flip'
import DriversLicense from "./steps/DriversLicense";

// // const stripePromise = loadStripe('pk_test_xC1Nf1Djo2wx3DF72PmBiC5W00bBLUgjpf');
// const stripe = require('stripe')('sk_test_k7kzz0R6mrRogFPs6OVrpgrB00UmEjcUtf');

export class ScrapperInfo {
  scrapperType?: ScrapperType
  firstName?: string
  middleName?: string
  lastName?: string
  phone?: string
  address?: string
  vehicleType?: VehicleType
  vehicleYear?: number
  vehicleMake?: string
  vehicleModel?: string
  hasAcceptedTax?: boolean
  hasAcceptedDeposit?: boolean
  watchedVideos?: boolean
  hasDriversLicense?: boolean
  submittedBackgroundCheck?: boolean
}

export default function ScrapperSignUp() {
  useEffect(() => {
    document.title = "Scrapper Sign Up | Trash Gurus";
  }, []);

  const [step, setStep] = useState(0)
  const [showForm, setShowForm] = useState(false)
  const [info, setInfo] = useState<ScrapperInfo>({})

  const onStepClick = (thisStep: number) => {
    return step >= thisStep ? () => {
      setStep(thisStep)
      setShowForm(true)
    } : undefined
  }

  const getNextStep = () => {
    if (info.submittedBackgroundCheck) {
      setStep(8)
    }
    else if (info.hasDriversLicense) {
      setStep(7)
    }
    else if (info.watchedVideos) {
      setStep(6)
    }
    else if (info.hasAcceptedDeposit) {
      setStep(5)
    }
    else if (info.hasAcceptedTax) {
      setStep(4)
    }
    else if (info.vehicleType !== undefined && info.vehicleYear && info.vehicleMake && info.vehicleModel) {
      setStep(3)
    }
    else if (info.firstName && info.lastName && info.phone && info.address) {
      setStep(2)
    }
    else if (info.scrapperType) {
      setStep(1)
    }
    else {
      setStep(0)
    }
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        {step}
        <CRow className="justify-content-center">
          <CCol lg={4} md={6} sm={8}>
            <ReactCardFlip isFlipped={showForm} flipDirection="horizontal">
              <CCard className="shadow">
                <CCardHeader>
                  <h2 className="text-center mb-0"><strong>Get Started</strong></h2>
                </CCardHeader>
                <CCardBody className="p-4">
                  <ScrapperSignUpWrapper>
                    <SignUpStep
                      title="Account Type"
                      subtitle={info.scrapperType?.valueOf()}
                      icon={step < 0 ? undefined : "check-circle"}
                      onOpenForm={onStepClick(0)}
                      currentStep={step === 0}
                      faded={false}
                    />
                    <SignUpStep
                      title="About Me"
                      subtitle={`${info.firstName ?? ""} ${info.lastName ?? ""}`}
                      icon={step < 1 ? undefined : "check-circle"}
                      onOpenForm={onStepClick(1)}
                      currentStep={step === 1}
                      faded={step < 1}
                    />
                    <SignUpStep
                      title="Vehicle"
                      subtitle={`${info.vehicleYear ?? ""} ${info.vehicleMake ?? ""} ${info.vehicleModel ?? ""}`}
                      icon={step < 2 ? undefined : "check-circle"}
                      onOpenForm={onStepClick(2)}
                      currentStep={step === 2}
                      faded={step < 2}
                    />
                    <SignUpStep
                      title="Tax"
                      icon={step < 3 ? undefined : "check-circle"}
                      onOpenForm={onStepClick(3)}
                      currentStep={step === 3}
                      faded={step < 3}
                    />
                    <SignUpStep
                      title="Direct Deposit"
                      icon={step < 4 ? undefined : "check-circle"}
                      onOpenForm={onStepClick(4)}
                      currentStep={step === 4}
                      faded={step < 4}
                    />
                    <SignUpStep
                      title="Videos"
                      icon={step < 5 ? undefined : "check-circle"}
                      onOpenForm={onStepClick(5)}
                      currentStep={step === 5}
                      faded={step < 5}
                    />
                    <SignUpStep
                      title="Drivers License"
                      icon={step < 6 ? undefined : "check-circle"}
                      onOpenForm={onStepClick(6)}
                      currentStep={step === 6}
                      faded={step < 6}
                    />
                    <SignUpStep
                      title="Background Check"
                      icon={step < 7 ? undefined : "check-circle"}
                      onOpenForm={onStepClick(7)}
                      currentStep={step === 7}
                      faded={step < 7}
                    />
                  </ScrapperSignUpWrapper>
                </CCardBody>
              </CCard>
              <CCard>
                <CCard className="shadow">
                  <CCardHeader>
                    <h2 className="text-center mb-0"><strong>Get Started</strong></h2>
                  </CCardHeader>
                  <CCardBody className="p-4">
                    {step === 0 &&
                      <AccountType
                        info={info}
                        onChange={setInfo}
                      />
                    }
                    {step === 1 &&
                      <AboutMe
                        info={info}
                        onChange={setInfo}
                      />
                    }
                    {step === 2 &&
                      <Vehicle
                        info={info}
                        onChange={setInfo}
                      />
                    }
                    {step === 3 && <Tax
                      info={info}
                      onChange={setInfo}
                    />}
                    {step === 4 && <DirectDeposit
                      info={info}
                      onChange={setInfo}
                    />}
                    {step === 5 && <Videos
                      info={info}
                      onChange={setInfo}
                    />}
                    {step === 6 && <DriversLicense
                      info={info}
                      onChange={setInfo}
                    />}
                    {step === 7 && <BackgroundCheck
                      info={info}
                      onChange={setInfo}
                    />}
                    <CRow>
                      <CCol className="px-0">
                        <CButton
                          className="float-end mt-4"
                          onClick={() => {
                            setShowForm(false)
                            getNextStep()
                          }}
                        >
                          Next <i className="fa fa-chevron-right" />
                        </CButton>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>


              </CCard>
            </ReactCardFlip>

          </CCol>
        </CRow>
      </CContainer>
    </div >


  );
}