import React from 'react';
import { CButton, CCol, CContainer, CImage, CRow } from '@coreui/react'
import "font-awesome/css/font-awesome.min.css"
import "../../../../assets/font-awesome/css/all.css"
import "./AppFeatures.css"
import orderSummary from "../../../../assets/img/svg/order-summary-2.svg"
import mapScreen from "../../../../assets/img/svg/map-screen.svg"

function AppFeatures() {
    return (
        <CContainer fluid>
            <CRow className="mx-5">
                <CCol md={6} sm={12}>
                    <div className="img-container pt-3">
                        <div className="svg-square-1 tg-bg-success" />
                        <div className="svg-square-2 bg-light" />
                        <CImage
                            fluid
                            src={orderSummary}
                            className="mx-auto d-block dark-shadow"
                        />
                    </div>
                </CCol>
                <CCol md={6} sm={12} className="my-auto mt-4 mt-sm-4">
                    <p className="tg-text-success">ON-DEMAND TRASH REMOVAL</p>
                    <h1 className="display-6">Instant booking with local waste haulers.</h1>
                    <p className="pt-4 pb-3">No more calls or finding a friend with a truck. Enter
                        your address and what waste services you're looking for. We'll connect
                        you with quotes from our trusted, local disposal partners. Pick an
                        option... and we'll do the rest.
                    </p>
                    <CButton color="success" href="https://thetrashgurus.com/" className="d-none d-sm-none">
                        Check Out All Services
                    </CButton>
                </CCol>
            </CRow>
            <CRow className="mx-5 mt-4 mt-sm-4">
                <CCol md={6} sm={12} className="my-auto">
                    <p className="tg-text-success">SERVICES OFFERED ON TRASH GURUS</p>
                    <h1 className="display-6">Widest selection of waste services.</h1>
                    <div className="py-4">
                        <table>
                            <tr>
                                <td><i className="fa fa-trash-can tg-text-success me-3" /></td>
                                <td>Curbside Garbage Collection</td>
                            </tr>
                            <tr>
                                <td><i className="fa fa-couch tg-text-success me-3" /></td>
                                <td>Junk Removal</td>
                            </tr>
                            <tr>
                                <td><i className="fa fa-dumpster tg-text-success me-3" /></td>
                                <td>Roll Off Dumpsters</td>
                            </tr>
                            <tr>
                                <td><i className="fa fa-recycle tg-text-success me-3" /></td>
                                <td>Shredding & E-Recycling</td>
                            </tr>
                            <tr>
                                <td><i className="fa fa-toilet tg-text-success me-3" /></td>
                                <td>Portable Toilets</td>
                            </tr>
                            <tr>
                                <td><i className="fa fa-face-smile tg-text-success me-3" /></td>
                                <td>And more...</td>
                            </tr>
                        </table>
                    </div>
                    <CButton color="success" href="https://thetrashgurus.com/">
                        Check Out All Services
                    </CButton>
                </CCol>
                <CCol md={6} sm={12}>
                    <div className="img-container pt-3">
                        <div className="svg-square-3 tg-bg-success" />
                        <div className="svg-square-4 bg-light" />
                        <CImage
                            fluid
                            src={mapScreen}
                            className="mx-auto d-block dark-shadow"
                        />
                    </div>

                </CCol>
            </CRow>
        </CContainer >
    )
}

export default AppFeatures
