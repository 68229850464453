import { CCol, CFormInput, CFormSelect, CRow } from "@coreui/react";
import React from "react";
import { ScrapperInfo } from "../ScrapperSignUp";
import StepWrapper from "./StepWrapper";

export enum VehicleType { car, suvTruck, boxTruck }

interface VehicleProps {
  info: ScrapperInfo
  onChange: (value: React.SetStateAction<ScrapperInfo>) => void
}

export default function Vehicle({ info, onChange }: VehicleProps) {
  return (
    <StepWrapper title="What vehicle do you drive?">
      <CRow>
        <CCol>
          <small className="ms-1">Type</small>
          <CFormSelect
            value={info.vehicleType}
            onChange={(e) => {
              console.log("Test")
              info.vehicleType = parseInt(e.currentTarget.value) as VehicleType
              onChange(info)
            }}
          >
            <option>Select Vehicle Type</option>
            <option
              value={VehicleType.car}
            >
              Car
            </option>
            <option
              value={VehicleType.suvTruck}
            >
              SUV/Truck
            </option>
            <option
              value={VehicleType.boxTruck}
            >
              Box Truck
            </option>
          </CFormSelect>
          <small className="ms-1">Year</small>
          <CFormInput
            type="number"
            value={info.vehicleYear}
            placeholder="2022"
            className="mb-2"
            onChange={(e) => {
              info.vehicleYear = parseInt(e.currentTarget.value)
              onChange(info)
            }}
          />
          <small className="ms-1">Make</small>
          <CFormInput
            value={info.vehicleMake}
            placeholder="NASA"
            className="mb-2"
            onChange={(e) => {
              info.vehicleMake = e.currentTarget.value
              onChange(info)
            }}
          />
          <small className="ms-1">Model</small>
          <CFormInput
            value={info.vehicleModel}
            placeholder="Space Shuttle"
            className="mb-2"
            onChange={(e) => {
              info.vehicleModel = e.currentTarget.value
              onChange(info)
            }}
          />
        </CCol>
      </CRow>
    </StepWrapper>

  );
}