import { CCol, CRow } from "@coreui/react";
import React from "react";

interface StepFormWrapperProps {
  title: String
  children: JSX.Element,
}

export default function StepFormWrapper({ title, children }: StepFormWrapperProps) {
  return (
    <CRow>
      <CCol>
        <h3 className="mb-5">{title}</h3>
        {children}
      </CCol>
    </CRow>
  );
}