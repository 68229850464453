import { CButton } from "@coreui/react";
import React from "react";
import { ScrapperInfo } from "../ScrapperSignUp";
import StepFormWrapper from "./StepWrapper";

export enum ScrapperType { individual, employee }

interface DirectDepositProps {
  info: ScrapperInfo
  onChange: (value: React.SetStateAction<ScrapperInfo>) => void
}

export default function DirectDeposit({ info, onChange }: DirectDepositProps) {

  return (
    <>
      <StepFormWrapper title="Let's get you paid">
        <>
          <iframe title="stripeConnectOnboarding" src="https://www.google.com" />
          <CButton onClick={() => {
            info.hasAcceptedDeposit = true
            onChange(info)
          }}>
            Complete Stripe Connect
          </CButton>
        </>
      </StepFormWrapper>
    </>
  );
}