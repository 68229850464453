import { CButton } from "@coreui/react";
import React from "react";
import { ScrapperInfo } from "../ScrapperSignUp";
import StepFormWrapper from "./StepWrapper";

export enum ScrapperType { individual, employee }

interface TaxProps {
  info: ScrapperInfo
  onChange: (value: React.SetStateAction<ScrapperInfo>) => void
}

export default function Tax({ info, onChange }: TaxProps) {
  return (
    <>
      <StepFormWrapper title="And now for the least exciting part...">
        <CButton onClick={() => {
          info.hasAcceptedTax = true
          onChange(info)
        }}>
          Accept Taxes
        </CButton>
      </StepFormWrapper>
    </>
  );
}