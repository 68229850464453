import { CCol, CFormCheck, CRow } from "@coreui/react";
import React, { useState } from "react";
import { ScrapperInfo } from "../ScrapperSignUp";

export enum ScrapperType { individual, employee }

interface BackgroundCheckProps {
  info: ScrapperInfo
  onChange: (value: React.SetStateAction<ScrapperInfo>) => void
}

export default function BackgroundCheck({ info, onChange }: BackgroundCheckProps) {
  const [type, setType] = useState<ScrapperType>()

  return (
    <>
      <CRow>
        <CCol>
          <h1>What statement fits your best?</h1>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CFormCheck
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault1"
            label="Individual looking for work"
            checked={type === ScrapperType.individual}
            onClick={() => setType(ScrapperType.individual)}
          />
          <CFormCheck
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault2"
            label="Employee creating an account"
            checked={type === ScrapperType.employee}
            onClick={() => setType(ScrapperType.employee)}
          />
        </CCol>
      </CRow>
    </>
  );
}